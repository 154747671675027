
























import Vue from "vue";
export default Vue.extend({
	name: "Community",
	data() {
		return {};
	},
	created() {
		if (this.getToken) this.reload();
	},
	watch: {
		getToken() {
			this.reload();
		}
	},
	methods: {
		reload() {
			if (!this.getClub.name || this.getClub.name.toLowerCase() != this.$route.params.club.toLowerCase()) {
				this.$store.commit("pushLoading", {
					name: "GET_CLUB",
					message: "동아리 불러오는 중"
				});
				this.$store
					.dispatch("GET_CLUB", this.$route.params.club)
					.then(club => {
						this.$store.commit("clearLoading", "GET_CLUB");
						if (!club) this.$router.push("/");
					})
					.catch(err => {
						this.$store.commit("clearLoading", "GET_CLUB");
						this.$router.push("/");
					});
			}
		},
		checkPermission(permission: number) {
			if (this.$store.state.club.ranks) {
				let user = this.$store.state.club.members.find((member: any) => {
					return member.user == this.$store.state.userInformation._id;
				});
				if (user)
					return (
						this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).isAdmin ||
						this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).permission.indexOf("" + permission) != -1
					);
				else return false;
			} else return false;
		}
	},
	computed: {
		getClub() {
			return this.$store.state.club;
		},
		getToken() {
			return this.$store.state.userToken;
		},
		isAdmin() {
			if (this.$store.state.club.ranks && this.$store.state.userInformation._id) {
				let user = this.$store.state.club.members.find((member: any) => {
					return member.user == this.$store.state.userInformation._id;
				});
				if (user) {
					return this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).isAdmin;
				} else return false;
			} else return false;
		}
	}
});
